import { FC } from 'react'
import { sanitizeUrl } from '@/utils'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import styles from './index.module.scss'

export const GlobalTopNavBar: FC = () => {
  const { t } = useTranslation(['global'])
  const router = useRouter()
  return (
    <div className={styles.container}>
      <section className={styles.section}>
        <div className={styles.content}>
          <p className={styles.text}>
            🎉{' '}
            <a
              href={sanitizeUrl(`https://invdb.protocol.ooo/${router.locale}`)}
              target='_blank'
              rel='noreferrer'
              className={styles.link}
            >
              {t('国内外の1万社以上の投資会社データベース ')}
              <span className={styles.smbr}>
                <br />
              </span>
              {t('TOSHIKA DB をリリース →')}
            </a>
          </p>
        </div>
      </section>
    </div>
  )
}
