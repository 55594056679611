import { FC, useEffect, useRef, useState } from 'react'
import { PageTransition } from 'next-page-transitions'
import { useRouter } from 'next/router'
import { GlobalFooter } from '@components-v2/organisms/GlobalFooter'
import { GlobalHeader } from '@components-v2/organisms/GlobalHeader'
import { ToastManager } from '@components-v2/organisms/ToastManager'
import { GlobalTopNavBar } from '@/componentsV2/organisms/GlobalTopNavBar'
import classNames from 'classnames'
import styles from './index.module.scss'

/**
 * サイドバーが不要でHeroがある場合のテンプレート
 */
export const PageWithHeroTemplate: FC = ({ children }) => {
  const router = useRouter()
  const [isHeaderFixed, setIsHeaderFixed] = useState(false)
  const topNavBarRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const intersectionObserver = new IntersectionObserver(
      ([entry]) => {
        setIsHeaderFixed(!entry.isIntersecting)
      },
      { threshold: 0 }
    )

    if (topNavBarRef.current) {
      intersectionObserver.observe(topNavBarRef.current)
    }

    return () => {
      if (topNavBarRef.current) {
        intersectionObserver.unobserve(topNavBarRef.current)
      }
    }
  }, [])

  return (
    <div className={styles.container}>
      <div ref={topNavBarRef}>
        <GlobalTopNavBar />
      </div>
      <GlobalHeader isHeaderFixed={isHeaderFixed} />
      <main
        className={classNames(styles.main, {
          [styles.addPadding]: isHeaderFixed,
        })}
      >
        <PageTransition timeout={300} classNames='page-transition'>
          <PageTransitionInner key={router.route}>{children}</PageTransitionInner>
        </PageTransition>
      </main>
      <GlobalFooter />
      <ToastManager />
    </div>
  )
}

// PageTransitionに直でHTMLとなる要素を渡すとエラーを吐くので、そのエラーを潰す用に噛ませるComponent
const PageTransitionInner: FC = ({ children }) => {
  return <>{children}</>
}
